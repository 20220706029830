import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql, useStaticQuery } from 'gatsby';

const seoQuery = graphql`
  {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

const SEO = ({ seo }) => {
  const data = useStaticQuery(seoQuery);

  return (
    <HelmetDatoCms
      seo={seo}
      favicon={data.datoCmsSite?.faviconMetaTags}
      htmlAttributes={{ lang: 'fi' }}
    />
  );
};

export default SEO;
